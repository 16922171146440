$accentClr: #2AB14B;
footer{
    .logo{ margin-bottom: 20px; display: inline-block;}
    .top{ background-color: $accentClr ; padding: 40px 0;
        p{ margin-bottom: 40px;}
    }
    .bottom{ background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #2AB14B; padding: 20px 0;
        p{ font-size: 12px; text-transform: uppercase;}
    }
    .social{
        a{ display: inline-block; margin: 0 15px;}
    }
    p{ width: 100%; max-width: 600px; margin: auto; color: #fff;}
}