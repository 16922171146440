$primary-family: "Poppins", sans-serif;
$primary-clr: #323232;
$accentClr: #2ab14b;

*,
ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.clr {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
body {
  font-family: $primary-family !important;
  font-size: 16px;
  color: $primary-clr !important;
  overflow-x: hidden;
}

h5 {
  font-size: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.fw--500 {
  font-weight: 500;
}
.fw--600 {
  font-weight: 600;
}
.fw--700 {
  font-weight: 700;
}

.pb--0 {
  padding-bottom: 0px !important;
}

.mb--0 {
  margin-bottom: 0px !important;
}
.mb--5 {
  margin-bottom: 5px;
}
.mb--70 {
  margin-bottom: 70px;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.btn {
  min-width: 180px;
  display: inline-flex!important;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  height: 56px;
  &.btn-primary {
    background-color: $accentClr;
    border-color: $accentClr;
    &:hover, &:active, &:focus{ background-color: #238b3c!important; border-color: #238b3c!important;}
  }
}

.link {
  color: $accentClr;
  text-decoration: underline;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    width: 15px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    transition: all linear 0.25s;
  }
  &:hover {
    text-decoration: none;
    &:after {
      margin-left: 15px;
    }
  }
}

section {
  padding: 100px 0;
  .container {
    max-width: 1240px;
  }
  .sectionTitlte {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.04em;
    margin-bottom: 30px;
    &::after {
      content: "";
      width: 200px;
      height: 4px;
      background-color: $accentClr;
      display: block;
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .sectionIntroTxt {
    width: 100%;
    max-width: 800px;
    margin: auto auto 50px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
