.clientsSection{ background-color: #F4F4F4; padding: 50px 0;
    .sectionTitlte{
        &:after{ display: none;}
    }
    
    .slick-arrow{ display: none!important;}
    .slick-slide{
        img{ margin: auto; filter: grayscale(1);}
        &.slick-current{
            img{ filter: grayscale(0)}
        }
    }
}