$accentClr: #2AB14B;

#services{
    @media only screen and (max-width: 767px) and (min-width: 320px){
       padding-bottom: 0px;
    }
    @media only screen and (max-width: 1180px) and (min-width: 768px){
        padding-bottom: 0px;
    }
    
}
.box{ background: #F4F4F4; border-radius: 12px; padding: 32px; margin-bottom: 30px; min-height: 358px; transition: all linear 0.25s;
    .iconHolder{ display: flex; width: 62px; height: 62px; border-radius: 8px; background-color: $accentClr; align-items: center; justify-content: center; margin-bottom: 24px;
        img{ max-width: 90%; max-height: 90%;}
    }
    h5{}
    p{ color: #646464; letter-spacing: -0.03em; line-height: 32px; margin-bottom: 24px;}
    .link{ 
        &::after{ background: url(./imgs/arrow.svg) no-repeat center center; display: inline-block;}
    }
    &:hover{ box-shadow: 0px 4px 40px 8px rgba(0, 0, 0, 0.1); }
}

.ourValuesSection{ padding-bottom: 100px;
    .imgHolder{
        @media only screen and (max-width: 767px) and (min-width: 320px){
            margin-bottom: 50px;
         }
         @media only screen and (max-width: 1180px) and (min-width: 768px){
             
         }
        img{ max-width: 100%;}
    }
    .txtHolder{ min-height: 100%; display: flex; align-items: flex-start; justify-content: center; flex-direction: column; 
        p{ margin-bottom: 24px;}
    }
    ul{ margin: 0px; padding: 0px;}
    .valuesHolder{
        .value{ border-radius: 8px; padding: 15px 0; transition: all linear 0.25s;
            .imgHolder{ width: 60px; height: 60px; border-radius: 8px; background: $accentClr; display: flex; align-items: center; justify-content: center; border: 6px solid transparent; float: left;
                @media only screen and (max-width: 767px) and (min-width: 320px){
                    margin-bottom: 0px;
                 }
                 @media only screen and (max-width: 1180px) and (min-width: 768px){
                     
                 }
                img{ max-width: 90%; max-height: 90%;}
            }
            .txt{ width: calc(100% - 60px); padding-left: 16px; float: left;
                h5{ transition: all linear 0.25s;}
            }
                &:hover{ box-shadow: 0px 4px 40px 8px rgba(0, 0, 0, 0.1); padding: 15px; 
                    .imgHolder{ border-color: rgba($accentClr, .2);}
                    h5{ color: $accentClr;}
            }
        }
    }
}

