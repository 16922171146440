#testimonials {
    .slick-arrow {
        display: none !important;
    }

    .slick-list {

        /*overflow: visible;max-height: 270px;*/
        @media only screen and (max-width: 767px) and (min-width: 320px) {
            max-height: inherit;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {
            max-height: inherit;
        }
    }

    // .slick-slide {
    //     margin: 0 25px;
    // }

    // .slick-track{ }
    // .slick-list {
    //     margin: 0 -25px;
    // }
    .sectionIntroTxt {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
    }

    .carouselHolder {
        width: 100%;
        max-width: 1180px;
        margin: auto;

        .testimonialHolder {
            padding: 0 25px;

            @media only screen and (max-width: 767px) and (min-width: 320px) {
                padding: 0 10px;
            }

            @media only screen and (max-width: 1180px) and (min-width: 768px) {
                padding: 0 10px;
            }
        }

        .testimonial {
            background: #FFFFFF;
            border: 2px solid #F0F0F0;
            border-radius: 12px;
            padding: 48px 32px 32px;
            width: 100%;
            position: relative;
            margin-top: 25px;

            @media only screen and (max-width: 767px) and (min-width: 320px) {
                padding: 48px 20px 20px;
            }

            @media only screen and (max-width: 1180px) and (min-width: 768px) {
                padding: 48px 20px 20px;
            }

            .txt {
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 2px solid #F0F0F0;

            }

            .quote-icon {
                position: absolute;
                left: 32px;
                top: -25px;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: -0.03em;
                color: #646464;
                margin-bottom: 0px;

                @media only screen and (max-width: 767px) and (min-width: 320px) {
                    font-size: 12px;
                    line-height: 24px;
                }

                @media only screen and (max-width: 1180px) and (min-width: 768px) {
                    font-size: 12px;
                    line-height: 24px;
                }
            }

            .user-meta-holder {
                display: flex;
                align-items: center;

                h5 {
                    @media only screen and (max-width: 767px) and (min-width: 320px) {
                        font-size: 16px;
                    }

                    @media only screen and (max-width: 1180px) and (min-width: 768px) {
                        font-size: 16px;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 14px;

                    @media only screen and (max-width: 767px) and (min-width: 320px) {
                        font-size: 12px;
                        line-height: 14px;
                    }

                    @media only screen and (max-width: 1180px) and (min-width: 768px) {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .img-holder {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 56px;
                    height: 56px;
                    border-radius: 100px;
                    background: #C4C4C4;
                    margin-right: 15px;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }

                    @media only screen and (max-width: 767px) and (min-width: 320px) {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }

                    @media only screen and (max-width: 1180px) and (min-width: 768px) {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}


.certificates-section {
    padding-bottom: 0px;

    .certificate-holder {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            margin-bottom: 50px;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {
            width: 100%;
            max-width: 588px;
            margin: 0 auto 50px;

        }

        img { display: block; margin: auto;
            max-width: 100%;
            max-height: 800px;
        }
    }
}

.custom-tabs {
    .nav-tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-item {
            margin: 0 1px;
        }

        .nav-link {
            color: #646464;
            font-size: 16px;
            font-weight: 500;
            background: #F4F4F4;
            border-radius: 12px 12px 0px 0px;
            min-width: 160px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 767px) and (min-width: 320px) {
                min-width: auto;
            }

            @media only screen and (max-width: 1180px) and (min-width: 768px) {}

            &.active {
                background-color: #2AB14B;
                color: #fff;
            }
        }
    }

    .tab-content {
        padding-top: 30px;

        .item {
            width: 100%;
            max-width: 286px;
            margin: auto;
            padding-bottom: 20px;
            margin-bottom: 30px;
            transition: all linear 0.25s;
            border-radius: 12px;

            &:hover {
                box-shadow: 0px 4px 24px rgb(0 0 0 / 16%);
            }

            .img-holder {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 286px;
                margin-bottom: 20px;

                img {
                    max-width: 90%;
                    max-height: 90%;
                }
            }

            h5 {
                font-size: 14px;
                ;
            }
        }
    }
}

.fw-700 {
    font-weight: 700;
}

.mb-50 {
    margin-bottom: 50px;
}

table {
    th {
        font-weight: 500;
    }

    th,
    td {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap
    }
}


.rent-tab-content{
    width: 100%; max-width: 1000px; margin: auto;
    .txt-holder{ margin-bottom: 50px; display: flex; flex-direction: column; align-items: flex-start; justify-content: center; min-height: 100%;
        ul,li{ padding: 0px; margin: 0px;}
        li{ font-size: 14px; font-weight: 500; margin-bottom: 5px;}
    }
    .img-holder{ margin-bottom: 50px;
        img{ max-width: 100%; border-radius: 50px;}
    }
}