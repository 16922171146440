.modal-dialog{ max-width: 1000px!important;
    .modal-header{ border-bottom: 0px;}
    .modal-body{ padding-bottom: 80px;
        h5{ font-size: 32px; font-weight: 700;}
        p{ font-size: 14px;}
        ul,li{ padding: 0px; margin: 0px;}
        li{ list-style: disc; display: inline-block; margin: 0 10px 10px 0; position: relative; font-size: 14px; font-weight: 500;
            &::before{ content: ''; display: inline-block; width: 5px; height: 5px; border-radius: 100%; background-color: #000; margin-right: 5px; position: relative; top: -2px;}
        }
        .slick-dots{ 
            // bottom: -60px;
            li{ width: 50px; height: 50px; 
                &::before{ display: none;}
                img{ filter: grayscale(1);}
                &.slick-active{
                    img{ filter: none;}
                }
            }
        }
    }
}