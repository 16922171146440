$accentClr: #2AB14B;

header {
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 20px 0;
    z-index: 4;

    @media only screen and (max-width: 767px) and (min-width: 320px) {
        padding: 15px 0;
    }

    .container {
        max-width: 1240px;
    }

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav__item {
        &:last-child {
            display: none;
        }
    }

    .nav__menu {
        transition: all linear 0.25s;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: 1s;

        .nav__list {
            opacity: 1;
        }

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            position: fixed;
            min-height: 100vh;
            width: 100%;
            top: 0px;
            left: auto;
            right: -105%;
            z-index: 3;
            transform: translateX(0%);
            overflow-y: auto;
            background-color: #fff;

            // testing
            .nav__list {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
            }

            .nav__item {
                text-align: center;
                display: block;
                padding: 15px;

                a {
                    color: #323232;

                    &.active {
                        color: $accentClr;
                    }
                }
            }

            .nav__toggle {
                position: absolute;
                right: 15px;
                top: 15px;
            }

            .nav__item {
                &:last-child {
                    display: block;
                    padding-right: 15px;
                }


            }

            &.show {
                display: block;
                right: 0px;

                .nav__list {
                    opacity: 1;
                    animation: fade .75s .75s both;
                }
            }
        }
    }

    .nav__list {
        padding: 0px;
        margin: 0px;

        li {
            list-style: none;
            display: inline-block;
            padding: 0 20px;

            @media only screen and (min-width: 767px) and (max-width: 1180px){
                padding: 0 10px;
            }

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }

            a {
                color: #fff;
                font-size: 16px;
                text-decoration: none;

                &:hover,
                &.active {
                    text-decoration: underline;
                    color: $accentClr;
                }
            }
        }
    }

    .getQuoteBtn {
        @media only screen and (max-width: 767px) and (min-width: 320px) {
            display: none!important;
        }
    }

    .nav__toggle {
        display: none;

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            display: block;
        }
    }
}