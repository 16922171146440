$accentClr: #2AB14B;

#home {
    position: relative;
    min-height: 100vh;

    .carousel {
        min-height: 100vh;
        z-index: 1;

        .carousel-control-prev,
        .carousel-control-next {
            display: none;
        }

        .bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            max-width: 100%;
            max-height: 100vh;
        }

        .carousel-inner,
        .carousel-item,
        .bg {
            min-height: 700px;
            min-height: 100vh;
        }

    }

    .txtHolder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        z-index: 2;

    }

    .bottomLogos {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 2;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(4px);
        padding: 15px 0;

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            display: none;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {
            overflow: hidden;
        }

        .container {
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                filter: grayscale(1);
            }
        }

    }

    h1 {
        font-size: 72px;
        line-height: 60px;
        font-weight: 600;
        letter-spacing: -0.04em;
        color: #000;

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            font-size: 40px;
            line-height: 40px;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {}

        .sub {
            font-size: 24px;
            letter-spacing: -.5px;
        }
    }

    p {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #000;
        font-weight: 500;

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            font-size: 18px;
            line-height: 24px;
            ;
        }

        @media only screen and (max-width: 1180px) and (min-width: 768px) {}
    }

    .txt {
        width: 100%;
        max-width: 600px;

        h1 {}
    }

    .fone-info {
        position: absolute;
        bottom: 200px;
        left: 0;
        width: 100%;
        z-index: 2;

        @media only screen and (max-width: 767px) and (min-width: 320px) {
            bottom: 100px;
        }

        @media only screen and (min-width: 1181px) and (max-width: 1600px) {
            bottom: 100px;
        }

        .info {
            display: flex;
            align-items: center;

            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.03em;
                color: #000;

                @media only screen and (max-width: 767px) and (min-width: 320px) {
                    font-size: 16px;
                    margin-bottom: 0px;
                }

                @media only screen and (max-width: 1180px) and (min-width: 768px) {}
            }

            p {
                margin-bottom: 0px;

            }

            p,
            a {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.04em;

                @media only screen and (max-width: 767px) and (min-width: 320px) {
                    font-size: 14px;
                }

                @media only screen and (max-width: 1180px) and (min-width: 768px) {}
            }

            a {
                text-decoration: none;
                color: #000;

                &:hover {
                    color: $accentClr;
                }
            }

            .icon {
                margin-right: 15px;

                @media only screen and (max-width: 767px) and (min-width: 320px) {
                    max-width: 40px;
                    margin-right: 5px;
                }

                @media only screen and (max-width: 1180px) and (min-width: 768px) {}
            }
        }
    }
}

.fixedWhatsapp {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 2;

    img {
        width: 60px;
        height: 60px;
    }

    @media only screen and (max-width: 767px) and (min-width: 320px) {}
}