$accentClr: #2AB14B;
.counterHolder{ text-align: center;
    .counter{ color: $accentClr;  height: 80px!important;
        span{ display: flex; align-items: center; justify-content: center; font-size: 72px; height: 100%;}
    }
    .title{ text-transform: capitalize; color: #323232; font-size: 24px; font-weight: 600;
        @media only screen and (max-width: 767px) and (min-width: 320px){
            
        }
        @media only screen and (max-width: 1180px) and (min-width: 768px){
            font-size: 18px;
        }
    }
}