$accentClr: #2AB14B;
.ourJourney{ background-color: #F4F4F4; padding-top: 50px;
    .sectionTitlte{
        &::after{ display: none;}
    }
    .locations{ padding-top: 50px; position: relative; overflow-x: hidden;
        img{ object-fit: cover; width: 100%;
            @media only screen and (max-width: 767px) and (min-width: 320px){
                /*width: auto; height: 375px;*/ object-position: bottom center;
            }
            @media only screen and (max-width: 1180px) and (min-width: 768px){
                
            }
        }
        .txt{ /*position: absolute; bottom: 50px; left: 50%; transform: translateX(-50%);*/ background:$accentClr; color: #fff; font-size: 16px; width: 100%; padding: 10px 15px; text-align: center;
            @media only screen and (max-width: 767px) and (min-width: 320px){
                font-size: 14px; bottom: 30px;
            }
            @media only screen and (max-width: 1180px) and (min-width: 768px){
                font-size: 14px; bottom: 20px;
            }
        }

    }
}