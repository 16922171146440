#bottom-form {
  background-color: #f4f4f4; position: relative;
  .backDrop{ position: absolute; width: 100%; height: 100%; z-index: 1; background-color: rgba(255, 255, 255, .75); left: 0px; top: 0px;}
  .sectionTitlte {
    &:after {
      left: 0px;
      transform: translateX(0);
    }
  }
  ul,
  li {
    padding: 0px;
    margin: 0px;
  }
  .meta {
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    margin-bottom: 30px;
    img {
      display: inline-block;
      margin-right: 15px;
    }
    a {
      color: #646464;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .form-holder { position: relative;
    background: #ffffff;
    border-radius: 12px;
    padding: 25px;
    .form-group {
      margin-bottom: 30px;
      input, textarea {
        background: #fafafa;
        border-radius: 4px;
        border: 0px;
        padding: 15px;
      }
    }
    
  }
}
