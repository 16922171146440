#about2{ padding-bottom: 0px;
    .row{ margin-bottom: 100px;}
    .sectionTitlte{
        &::after{ left: 0px; transform: translateX(0%); }
    }
    .imgHolder{ width: 100%; max-width: 582px; margin: auto;
        @media only screen and (max-width: 767px) and (min-width: 320px){
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 1180px) and (min-width: 768px){
            margin-bottom: 50px;
        }
    }
    .txt{ min-height: 100%; display: flex; align-items: flex-start; flex-direction: column; justify-content: center;
        p{ letter-spacing: -0.03em; color: #646464; }
    }

    .founderMsg{
        .imgCol{ order: 2;
            @media only screen and (max-width: 767px) and (min-width: 320px){
                order: 1;
            }
            @media only screen and (max-width: 1180px) and (min-width: 768px){
                order: 1;
            }
        }
        .txtCol{ order: 1;
            @media only screen and (max-width: 767px) and (min-width: 320px){
                order: 2;
            }
            @media only screen and (max-width: 1180px) and (min-width: 768px){
                order: 2;
            }
        }
    }
}